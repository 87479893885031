'use client';

import ThemedComponents from '@nrdy-marketing-engine/theming/src/themed-components';
import { useMemo } from 'react';
import type { NspContainerProps } from '../@types/schema';
import filterKnownComponents from './filter-known-components';
import NspComponent from './nsp-component';
import NspContainerProvider from './nsp-container-provider';

interface NspContainersProps {
  /** The items received from NSP. */
  items?: NspContainerProps[];
  metadata?: Record<string, unknown>;
  sectionId?: string;
}

/**
 * A component that dynamically inserts container components from a modular blocks field in NSP.
 * Containers are like regular NSP components but sit at the top level and can set theme.
 */
function NspContainers({ items = [], ...metadata }: NspContainersProps) {
  // except on dev, filter out components we cannot render to prevent errors
  const filteredItems = useMemo(() => filterKnownComponents(items), [items]);

  return (
    <ThemedComponents>
      {filteredItems.map(({ theme, sectionId, spaced, sticky, ...props }, index) => (
        <NspContainerProvider key={index} spaced={spaced} sticky={sticky} theme={theme}>
          <div data-container-key={index} id={sectionId}>
            <NspComponent {...metadata} {...props} />
          </div>
        </NspContainerProvider>
      ))}
    </ThemedComponents>
  );
}

export default NspContainers;
