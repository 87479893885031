'use client';

import { dataLayerPush } from '@nrdy-marketing-engine/gtm/src/data-layer-actions';
import { track } from '@nrdy-marketing-engine/tracking/src/track';
import { useEffect } from 'react';

const SeoSplitTestDataPusher = ({ data }: { data: any }) => {
  useEffect(() => {
    const pushValues = () => {
      const dataPayload = data || {
        url: 'none',
        subject: 'none',
        vt_type: 'none',
        location: 'none',
        page_type: 'none',
        segment: 'none',
      };

      if (!(window as any).dataLayer) {
        track('dataLayer_error', {
          category: 'dataLayer',
          label: 'dataLayer not found',
          value: dataPayload.segment,
        });
      } else {
        track('dataLayer_success', {
          category: 'dataLayer',
          label: 'dataLayer found, pushing values',
          value: dataPayload.segment,
        });
        const eventName = 'seoLookupEvent';
        dataLayerPush(eventName, dataPayload);
        track(eventName, {
          category: 'seo',
          label: eventName,
          value: dataPayload.segment,
        });
      }
    };
    pushValues();
  }, []);

  return null;
};

export default SeoSplitTestDataPusher;
