'use client';

import { type ReactNode, useMemo } from 'react';
import type { CmsResult } from './fetch-nsp-data';

import NspContext from './nsp-context';
import NspDataWrapper, { LocaleData } from './nsp-data-wrapper';

/** A React context provider for an NSP object. */
const NspProvider = ({
  value,
  locale,
  children,
}: {
  children: ReactNode;
  locale?: LocaleData;
  value: CmsResult;
}) => {
  const wrapper = useMemo<NspDataWrapper>(() => new NspDataWrapper(value, locale), [value, locale]);
  return <NspContext.Provider value={wrapper}>{children}</NspContext.Provider>;
};

export default NspProvider;
