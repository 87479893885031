interface NotImplementedProps {
  /** The name of the missing component. */
  model: string;
}

/**
 * A component that displays a placeholder in development only for a named component,
 * thus showing visibly that the component is not implemented yet.
 */
function NotImplemented({ model }: NotImplementedProps) {
  if (process.env.NODE_ENV === 'production') return null;
  return <div className="text-2xl text-center bg-gray-100 text-gray-400 p-4">[ {model} ]</div>;
}

export default NotImplemented;
