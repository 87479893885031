import { NotImplemented } from '@nrdy-marketing-engine/not-implemented';
import { useMemo } from 'react';
import { NspComponentProps } from '../@types/schema';
import { useComponentMap } from './get-component-map';
import getModel from './get-model';

/**
 * Dynamically renders an NSP component based on its `_modelApiKey` and props.
 */
interface ModelProps {
  __typename?: string;
  _modelApiKey?: string;
}

export const useModel = ({ __typename, _modelApiKey }: ModelProps) =>
  useMemo(() => getModel({ __typename, _modelApiKey }), [__typename, _modelApiKey]);

function NspComponent(props: NspComponentProps) {
  const model = useModel(props);
  const Component = useComponentMap(model);
  return Component ? <Component {...props} /> : <NotImplemented model={model} />;
}

export default NspComponent;
