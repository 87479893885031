import dynamic from 'next/dynamic';
import { ComponentType, useMemo } from 'react';
import { NspComponentProps } from '../@types/schema';

/*
The component map translates model keys (e.g. `text_container`) into their dynamic component
counterparts, using the `dynamic()` function of NextJS. Since we do not know at compile
time what components will be ready for a page, we make use of NextJS's dynamic rendering.
*/

// Dynamically loaded components here
const Accordion = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/accordion').then((mod) => mod.Accordion)
);
const FaqAccordion = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/accordion/src/faq-accordion').then((mod) => mod.default)
);
const BigDataBlock = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/big-data').then((mod) => mod.BigDataBlock)
);
const Buttons = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/components').then((mod) => mod.Buttons)
);
const ButtonCard = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/components').then((mod) => mod.ButtonCard)
);
const CardViewBlock = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/card-view/src/card-view-block').then((mod) => mod.default)
);
const CardViewContainer = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/card-view/src/card-view-container').then((mod) => mod.default)
);
const CarouselContainer = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/card-view/src/components/carousel-container').then(
    (mod) => mod.default
  )
);
const GridBlock = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/grid-container').then((mod) => mod.GridBlock)
);
const GridContainer = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/grid-container').then((mod) => mod.GridContainer)
);
const ImageCard = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/image-card').then((mod) => mod.ImageCard)
);

const InformationContent = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/form-wizard/src/informational-content/information-content').then(
    (mod) => mod.default
  )
);

const InformationDidYouKnow = dynamic<NspComponentProps>(() =>
  import(
    '@nrdy-marketing-engine/form-wizard/src/informational-content/information-did-you-know'
  ).then((mod) => mod.default)
);

const InformationGuarantee = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/form-wizard/src/informational-content/information-guarantee').then(
    (mod) => mod.default
  )
);
const InformationIllustration = dynamic<NspComponentProps>(() =>
  import(
    '@nrdy-marketing-engine/form-wizard/src/informational-content/information-illustration'
  ).then((mod) => mod.default)
);

const InformationReview = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/form-wizard/src/informational-content/information-review').then(
    (mod) => mod.default
  )
);

const InformationImage = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/form-wizard/src/informational-content/information-image').then(
    (mod) => mod.default
  )
);

const LeadFormPanel = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/components').then((mod) => mod.LeadFormPanel)
);

const MediaBlock = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/components').then((mod) => mod.MediaBlock)
);
const MembershipPlanContainer = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/price-card/src/membership-plan-container').then(
    (mod) => mod.default
  )
);
const MicrositeFooter = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/components').then((mod) => mod.MicrositeFooter)
);
const PressBar = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/press-bar').then((mod) => mod.PressBar)
);
const CTABanner = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/cta-banner').then((mod) => mod.CTABanner)
);
const PriceCard = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/components').then((mod) => mod.PriceCard)
);
const ReviewCard = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/components').then((mod) => mod.ReviewCard)
);
const HtmlContainer = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/html-container').then((mod) => mod.HtmlContainer)
);
const SchedulerBlock = dynamic<NspComponentProps>(
  () => import('@nrdy-marketing-engine/scheduler/src/scheduler-block')
);
const SpotIllustrationBlock = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/spot-illustration').then((mod) => mod.SpotIllustrationBlock)
);
const StructuredText = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/structured-text').then((mod) => mod.StructuredText)
);
const Testimonial = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/testimonial').then((mod) => mod.Testimonial)
);
const TestimonialContainer = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/testimonial').then((mod) => mod.TestimonialContainer)
);
const TrustPilotContainer = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/trust-pilot').then((mod) => mod.TrustPilotContainer)
);
const TrustPilotContentBlock = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/trust-pilot').then((mod) => mod.TrustPilotContentBlock)
);
const TwoColumnContainer = dynamic<NspComponentProps>(() =>
  import('@nrdy-marketing-engine/components').then((mod) => mod.TwoColumnContainer)
);

function getComponentMap(): Record<string, ComponentType<NspComponentProps>> {
  return {
    accordion_container: Accordion,
    big_data_block: BigDataBlock,
    button_block: Buttons,
    button_card: ButtonCard,
    card_view_block: CardViewBlock,
    card_view_container: CardViewContainer,
    carousel_container: CarouselContainer,
    cta_banner: CTABanner,
    faq_accordion: FaqAccordion,
    footer: MicrositeFooter,
    grid_block: GridBlock,
    grid_container: GridContainer,
    html_container: HtmlContainer,
    image_card_content: ImageCard,
    information_content: InformationContent,
    information_did_you_know: InformationDidYouKnow,
    information_guarantee: InformationGuarantee,
    information_illustration: InformationIllustration,
    information_image: InformationImage,
    information_review: InformationReview,
    media_block: MediaBlock,
    membership_plan_container: MembershipPlanContainer,
    nme_lead_panel: LeadFormPanel,
    press_bar: PressBar,
    price_card: PriceCard,
    review_card: ReviewCard,
    scheduler_block: SchedulerBlock,
    spot_illustration_block: SpotIllustrationBlock,
    structured_text_block: StructuredText,
    testimonial_container: TestimonialContainer,
    testimonial_content_block: Testimonial,
    text_container: StructuredText,
    trust_pilot_container: TrustPilotContainer,
    trust_pilot_content_block: TrustPilotContentBlock,
    two_column_container: TwoColumnContainer,
  };
}

export const useComponentMap = (component) =>
  useMemo(() => getComponentMap()[component], [component]);

export default getComponentMap;
