'use client';

import { CookieData } from '@nrdy-marketing-engine/ab-test/src/resolve-ab-test-app-router';
import { useEffect } from 'react';

const buildCookieString = ({
  cookieName,
  cookieValue,
  domain,
  expires,
  path,
  secure,
}: CookieData) => {
  let cookieString = `${cookieName}=${cookieValue};`;
  if (expires) cookieString += `expires=${expires};`;
  if (domain) cookieString += `domain=${domain};`;
  if (path) cookieString += `path=${path};`;
  if (secure) cookieString += 'secure;';
  return cookieString;
};

const CookieSetter = ({ cookieName, cookieValue, domain, expires, path, secure }: CookieData) => {
  useEffect(() => {
    const setCookie = () => {
      if (cookieName && cookieValue) {
        document.cookie = buildCookieString({
          cookieName,
          cookieValue,
          domain,
          expires,
          path,
          secure,
        });
      }
    };

    setCookie();
  }, [cookieName, cookieValue, domain, expires, path, secure]);

  return null;
};

export default CookieSetter;
