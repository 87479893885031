import { snakeCase } from '@s-libs/micro-dash';

export default function getModel({
  __typename,
  _modelApiKey,
}: {
  __typename?: string;
  _modelApiKey?: string;
}) {
  const key = __typename || _modelApiKey;
  return key && snakeCase(key.replace(/Record$/, ''));
}
