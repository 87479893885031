import { NspComponentProps } from '../@types/schema';
import getComponentMap from './get-component-map';
import getModel from './get-model';

/**
 * Makes a list of components "safe" by filtering them on production to only those that are known.
 */
export default function filterKnownComponents<T extends NspComponentProps>(items: T[]) {
  const knownComponents = Object.keys(getComponentMap());
  return items.filter(
    (item) => knownComponents.includes(getModel(item)) || process.env.NODE_ENV !== 'production'
  );
}
